@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

:root {
    --rosado: #E51F5F;
    --rosado-claro: #FFA1BD;
    --rosado-transparente: #cd265281;
    --negro-transaprente: #00000083;
    --negro: #000000;
    --blanco: #ffffff;
    --gris-claro: #dbdbdb;
}

* {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

.App {
    position: relative;
    width: 100%;
    /*height: 100vh;*/
}

.App-header {
    background-color: var(--rosado);
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-header h1 {
    color: var(--blanco);
    width: 90%;
    font-size: 55px;
    line-height: 100%;
}

.banner-principal {
    position: relative;
    width: 100%;
    height: calc(100vh - 150px);
    overflow: hidden;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    /* Asegura que el video cubra todo el fondo sin distorsionarse */
    z-index: -1;
    /* Coloca el video detrás de otros elementos */
}

.overlay-content {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
}

/***FORM ARRIBA **/
.cont-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: -moz-fit-content;
    height: fit-content;
    width: 500px;
    background-color: var(--blanco);
    border-radius: 20px;
    position: relative;
    z-index: 3;
    box-shadow: 1px 7px 8px rgba(0, 0, 0, 0.349);
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
}

.cont-text-header {
    padding: 20px 0;
}

.cont-text-header img {
    width: 250px;
}

.cont-form-top-der {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;
}

.cont-input-item {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-bottom: 15px;
}

.cont-input-item input {
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: calc(100% - 20px);
    background-color: var(--gris-claro);
}

.cont-input-item select,
.drop-down {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: var(--gris-claro);
    /*width: calc(100% - 20px);*/
}

.Dropdown-control {
    padding: 10px !important;
    border-radius: 10px !important;
    border: none !important;
    background-color: var(--gris-claro) !important;
    font-size: 14px;
}

.btn-send {
    padding: 10px 30px;
    background-color: var(--rosado);
    border-radius: 50px;
    border: var(--blanco) solid 2px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    color: var(--blanco);
    cursor: pointer;
    width: fit-content;
}

.cont-input-item span {
    font-size: 13px;
    color: var(--rosado);
    font-weight: 500;
}

.label-form {
    display: flex;
    gap: 10px;
    align-items: center;
}

.label-form p {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.label-form .cont-icon {
    width: 10px;
}

.vanilla-calendar {
    width: 400px !important;
}

.success-text {
    max-width: 300px;
    font-size: 30px;
    font-weight: 600;
    color: var(--rosado);
    text-align: center;
}

.cont-btn-book {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 30px 0;
    gap: 15px;
  }
  .cont-btn-book p {
    font-size: 13px;
    font-family: 'Fredoka';
    letter-spacing: -0.02em;
    font-weight: 400;
  }
  
  .cont-btn-book p strong {
    font-size: 14px;
    font-family: 'Fredoka';
    letter-spacing: -0.02em;
    font-weight: 700;
  }
  
  /*** BUTONS BANNER **/
.cont-buttons-actions-banner {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 0;
}
  .button-action-banner {
    background-color: var(--rosado);
    color: var(--blanco);
    text-decoration: none;
    display: flex;
    gap: 20px;
    align-items: center;
    border-radius: 0 50px 50px 0;
    padding: 5px 20px;
    border: var(--blanco) solid 1px;
    width: fit-content;
    cursor: pointer;
  }
  .button-action-banner p {
    font-weight: 800;
    font-size: 20px;
  }
  .button-action-banner-icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /***FIN BANER*/

  .title-section {
    font-size: 40px;
    font-weight: 800;
    color: var(--rosado);
    text-align: center;
  }

  /** CARUSEL */
  .seccion-carusel-pets {
    margin-top: 40px;
    padding-bottom: 50px;
    position: relative;
  }
  .seccion-carusel-pets h2 {
    position: relative;
    font-size: 45px;
    font-weight: 700;
    color: var(--rosado);
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
    z-index: 1;
  }
  .carusel-pets {
    height: 400px;
    background-position: center;
    background-size: cover;
    border-radius: 25px;
    margin: 0 5px;
    z-index: 1;
  }
  .backgound-sliders {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .backgound-sliders .bl {
    background-color: var(--blanco);
    height: 50%;
  }
  .backgound-sliders .rs {
    background-color: var(--rosado);
    height: 50%;
  }
  .react-multi-carousel-dot button {
    border: none !important;
  }
  .react-multi-carousel-dot--active button {
    background: #0741ff !important;
  }

  /***FACS*/
  .title-faqs {
    font-size: 35px;
    text-align: center;
  }
  .container-faqs {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    flex-wrap: wrap;
  }
  .faq {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    width: calc(100vw / 7);
    background-color: var(--rosado);
    padding: 60px 25px 25px 25px;
    border-radius: 50px;
  }
  .title-faqs {
    font-size: 45px;
    font-weight: 700;
    color: var(--rosado);
    text-align: center;
    z-index: 1;
  }
  .title-faq {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 1;
    flex-direction: column;
  }
  .title-faq span {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
  }
  .cont-svg-chek {
    width: 35px;
    height: 35px;
  }
  .sub-title {
    font-weight: 700;
    text-align: center;
    z-index: 1;
  }
  .faq-text-info {
    font-weight: 400;
    text-align: center;
    z-index: 1;
  }
  .back-faq {
    background-color: #FFE8EF;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 20px;
    left: 0;
    z-index: 0;
    border-radius: 50px;
  }

  /** RESENAS  */
  .cont-resena {
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
  }
  .cont-seccion-resenas {
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 30px 0;
  }
  .resenas-izquierda {
    width: 40%;
  }
  .resenas-izquierda img {
    width: 100%;
  }
  .resenas-derecha {
    width: 60%;
  }
  .grw-header {
    display: flex;
    align-items: center;
  }
  .cont-carusel-resenas {
    width: 70%;
    margin: 0 auto;
  }

  /** FOTER */
  .footer {
    background-color: var(--rosado);
    display: flex;
    padding-top: 30px;
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .footer-iz {
    width: 50%;
  }
  .footer-dr {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    position: relative;
  }
  .footer-tel {
    text-decoration: none;
    color: var(--blanco);
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    z-index: 2;
  }
  .iframe-map {
    z-index: 2;
  }
  .footer-tel .icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-tel p {
    font-size: 35px;
    font-weight: 600;
  }
  .text-map {
    font-size: 30px;
    font-weight: 600;
    color: var(--blanco);
    text-align: center;
    line-height: 110%;
    width: 90%;
  }

  /*** FORM 2**/
  .cont-form-footer {
    background-color: var(--rosado-claro);
    padding: 30px 0;
    border-radius: 0 200px 0 0;
    margin-top: 30px;
  }
  .cont-form2 {
    background-color: var(--blanco);
    margin: 0px 30px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px 0;
    border-radius: 0 180px 0 0;
  }
  .form2-width {
    width: 60%;
  }

  .form2-patitas {
    height: 120px;
    width: 100%;
    padding-left: 0;
    display: flex;
    justify-content: center;
  }

  .cont-patas-contact {
    height: 70px;
    width: 70px;
    margin-top: -35px;
  }

  .patas2 {
    width: 180px;
  }
  .patas-transp {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  @media (max-width: 600px) {
    .footer {
        flex-direction: column-reverse;
    }
    .footer-dr {
        width: 90%;
        margin: 0 auto;
    }
    .footer-iz {
        width: 100%;
        margin: 0 auto;
    }
    .cont-form-footer {
        border-radius: 0;
    }
    .cont-form2 {
        flex-direction: column;
        border-radius: 0;
        margin: 0;
    }
    .form2-width {
        width: 90%;
        margin: 0 auto;
    }
    .cont-btn-book {
        flex-direction: column-reverse;
    }
    .iframe-map {
        width: 90%;
        margin: 0 auto;
    }
    .grw-header {
        flex-direction: column;
      }
      .grw-header-inner {
        width: 100% !important;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
      .cont-carusel-resenas {
        width: 90% !important;
      }
      .cont-seccion-resenas {
        height: fit-content;
        flex-direction: column;
        gap: 40px;
        margin-top: -20px;
      }
      .resenas-izquierda {
        width: 100%;
      }
      .resenas-derecha {
        width: 100%;
      }
      .faq {
        width: 83.33333vw;
    }
    .container-faqs {
        flex-wrap: wrap;
        gap: 45px;
    }
    .App-header h1 {
        font-size: 25px;
        text-align: center;
    }
    .cont-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: fit-content;
        width: 90%;
        background-color: var(--blanco);
        border-radius: 20px;
        position: relative;
        z-index: 3;
        box-shadow: 1px 7px 8px rgba(0, 0, 0, 0.349);
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
    }
    .cont-buttons-actions-banner {
        display: none;
    }

    .fixed-datepicker {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      z-index: 1000;
    }
}

/* Media query para dispositivos con resolución HD */
@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  .cont-input-item input, .cont-input-item select, .drop-down {
    font-size: 12px;
    padding: 5px;
  }

  .cont-input-item {
    margin-bottom: 5px;
  }

  .label-form p {
    font-size: 12px;
  }
  .cont-text-header img {
    width: 150px;
  }

  .cont-text-header {
    padding-bottom: 0;
    padding-top: 5px;
  }

  .btn-send {
    padding: 5px 30px;
    font-size: 14px;
  }

  .cont-input-item span {
    font-size: 10px;
  }

  .cont-btn-book {
    margin: 10px 0 10px 0;
  }
}
